* {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

a {
    text-decoration: none;
    color: unset;
}

.text-cut {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.flex-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* CSS scroll-bar for the whole app. */
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
	border-radius: 100px;
}
/* Track */
::-webkit-scrollbar-track {
	background:  #dcdcdc;
	border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(136, 136, 136, 0.829);
	border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.MuiDataGrid-columnHeaders {
    min-height: 36px !important;
    max-height: 36px !important;
    line-height: 36px !important;
}
